<template>
  <v-dialog
    v-model="isOpen"
    max-width="1000"
    @input="handleClose"
  >
    <v-card>
      <v-card-title class="headline pb-10">
        <div class="columns">
          <div class="column" v-if="currentBanner.id == null">
            <h2 class="title is-3">Создать баннер</h2>
          </div>
          <div class="column" v-if="currentBanner.id != null">
            <h2 class="title is-3">Изменить баннер: {{ currentBanner.name }}</h2>
          </div>
        </div>
      </v-card-title>
      <v-card-text>
        <v-layout row>
          <v-layout column>
            <b-field label="Название">
              <b-input v-model="currentBanner.name" :disabled="currentBanner.id != null"></b-input>
            </b-field>
            <b-field label="HTML код баннера">
              <b-input v-model="currentBanner.description"></b-input>
            </b-field>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close()">Закрыть</v-btn>
        <v-btn text color="primary" v-if="currentBanner.id == null" @click="createBanner()">Добавить</v-btn>
        <v-btn text color="primary" v-if="currentBanner.id != null" @click="updateBanner()">Обновить</v-btn>
      </v-card-actions>
      <overlay-loader :show="isLoading"></overlay-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import OverlayLoader from '@/app/shared/components/app-loader/Loader';
export default {
  name: 'BannerDetailsModal',
  components: {
    OverlayLoader,
  },
  props: [
    'application_id',
  ],
  data() {
    return {
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.IS_CURRENT_BANNER_LOADING;
    },
    isOpen() {
      return this.$store.getters.IS_BANNER_DETAILS_MODAL_OPENED;
    },
    currentBanner: {
      get() {
        return this.$store.getters.GET_CURRENT_BANNER;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_BANNER", value);
      },
    },
  },
  methods: {
    close() {
      this.$store.dispatch("CURRENT_BANNER", {});
      this.$store.dispatch('BANNER_DETAILS_MODAL', false);
    },
    handleClose(isOpen) {
      if (!isOpen) {
        this.close();
      }
    },
    createBanner() {
      this.currentBanner.application_id = this.application_id;
      this.currentBanner.typeOfOffer = 'banner';
      this.$store.dispatch('CREATE_OFFER', this.currentBanner)
        .then(() => {
          this.$toast.success("Баннер успешно добавлен");
          this.$store.dispatch("CURRENT_BANNER", { });
          this.$store.dispatch('BANNER_DETAILS_MODAL', false);
          this.$store.dispatch("GET_APPLICATION_BY_ID", this.application_id);
        })
        .catch(() => {
          this.$toast.error("Ошибка при добавлении баннера");
        });
    },
    updateBanner() {
      this.currentBanner.typeOfOffer = 'banner';
      this.$store
        .dispatch("UPDATE_OFFER", this.currentBanner)
        .then(() => {
          this.$toast.success("Баннер успешно изменен");
          this.$store.dispatch("CURRENT_BANNER", { });
          this.$store.dispatch('BANNER_DETAILS_MODAL', false);
          this.$store.dispatch("GET_APPLICATION_BY_ID", this.application_id);
        })
        .catch(() => {
          this.$toast.error("Ошибка при добавлении баннера");
        });
    },
  },
};
</script>
<style>
.draggable-item {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 10px;
  background-color: #fff;
}
</style>
